import React from "react"
import { graphql } from "gatsby"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import Seo from "../../../components/seo"
import Layout from "../../../app/layout"

import { Row, Col, Breadcrumb } from "antd"

import Container from "../../../components/Container"
import { ChevronRight } from "../../../components/Icon"

import CardTeam from "../../../components/CardTeam/CardTeam"
import ScrollAnimation from "react-animate-on-scroll"

const DireksiPage = ({
  data: {
    aboutManagementDirectors: data = {
      page_setting: {},
      items: [],
    },
  },
}) => {
  const { t } = useI18next()

  // const { data: posts } = usefetcher("/about/management/directors", [])
  return (
    <Layout module_name="about" page_name="direksi">
      <Seo title={t("Direksi")} module_name="about" page_name="direksi" />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.page_setting.page_title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h1 className="section-title-huge">
              {data.page_setting.page_title}
            </h1>
          </ScrollAnimation>
          <div style={{ maxWidth: "767px", margin: "0 auto" }}>
            <Row gutter={30} style={{ marginTop: 40 }} justify="center">
              {data.items
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map(function (item, i) {
                  if (item.order === 1) {
                    return (
                      <Row
                        style={{ width: "100%", marginBottom: 30 }}
                        justify="center"
                        key={i}
                      >
                        <Col
                          span={24}
                          sm={12}
                          style={{ paddingLeft: 15, paddingRight: 15 }}
                        >
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}
                          >
                            <CardTeam
                              image={item.photo}
                              nama={item.name}
                              status={item.position}
                              jabatan={item.jabatan}
                              deskripsi={item.description}
                              btnText={t("Selengkapnya")}
                            />
                          </ScrollAnimation>
                        </Col>
                      </Row>
                    )
                  } else {
                    return (
                      <Col span={24} sm={12} key={i}>
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOnce={true}
                        >
                          <CardTeam
                            image={item.photo}
                            nama={item.name}
                            status={item.position}
                            jabatan={item.jabatan}
                            deskripsi={item.description}
                            btnText={t("Selengkapnya")}
                          />
                        </ScrollAnimation>
                      </Col>
                    )
                  }
                })}
            </Row>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default DireksiPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index", "page"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    aboutManagementDirectors(lang: { eq: $language }) {
      page_setting {
        page_title
        page_name
        slug
      }
      items {
        description
        jabatan
        name
        order
        photo
        position
      }
    }
  }
`
